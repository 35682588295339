import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/workspace/myru-landing/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/workspace/myru-landing/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/workspace/myru-landing/node_modules/react-animated-cursor/dist/index.es.js");
;
import(/* webpackMode: "eager", webpackExports: ["SoundProvider"] */ "/home/workspace/myru-landing/src/app/context/soundContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBar"] */ "/home/workspace/myru-landing/src/components/main/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/workspace/myru-landing/src/components/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/src/components/ui/cursor.tsx");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/src/components/ui/wawe.tsx");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/src/styles/globals.css");
