/* CANVAS V */
'use client';
import React, { useEffect, useRef, useState } from 'react';

interface Point {
    x: number;
    y: number;
    dx: number;
    dy: number;
}

interface RGBColor {
    r: number;
    g: number;
    b: number;
}

const TrailEffect: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [mouseMoved, setMouseMoved] = useState(false);
    const pointer = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2 });

    const params = {
        pointsNumber: 10,
        widthFactor: 0.3,
        spring: 0.4,
        friction: 0.5,
        glowIntensity: 15,
    };

    const trail = useRef<Point[]>(
        Array.from({ length: params.pointsNumber }, () => ({
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
            dx: 0,
            dy: 0,
        }))
    );

    const colors: RGBColor[] = [
        { r: 255, g: 0, b: 77 },
        { r: 0, g: 255, b: 128 },
        { r: 0, g: 128, b: 255 },
        { r: 128, g: 0, b: 255 },
        { r: 255, g: 255, b: 0 },
    ];

    const currentColorIndex = useRef(0);
    const rgbColor = useRef<RGBColor>({ ...colors[0] });
    const colorTransitionSpeed = 0.05;

    const updateMousePosition = (x: number, y: number) => {
        pointer.current.x = x;
        pointer.current.y = y;
        setMouseMoved(true);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');

        const setupCanvas = () => {
            if (canvas) {
                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
            }
        };

        const updateColor = () => {
            const nextColorIndex = (currentColorIndex.current + 1) % colors.length;
            const nextColor = colors[nextColorIndex];

            rgbColor.current.r += (nextColor.r - rgbColor.current.r) * colorTransitionSpeed;
            rgbColor.current.g += (nextColor.g - rgbColor.current.g) * colorTransitionSpeed;
            rgbColor.current.b += (nextColor.b - rgbColor.current.b) * colorTransitionSpeed;

            if (
                Math.abs(rgbColor.current.r - nextColor.r) < 1 &&
                Math.abs(rgbColor.current.g - nextColor.g) < 1 &&
                Math.abs(rgbColor.current.b - nextColor.b) < 1
            ) {
                currentColorIndex.current = nextColorIndex;
            }
        };

        const update = () => {
            if (!ctx || !canvas) return;

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            updateColor();

            trail.current.forEach((p, pIdx) => {
                const prev = pIdx === 0 ? pointer.current : trail.current[pIdx - 1];
                const spring = pIdx === 0 ? params.spring * 0.4 : params.spring;

                p.dx += (prev.x - p.x) * spring;
                p.dy += (prev.y - p.y) * spring;

                p.dx *= params.friction;
                p.dy *= params.friction;

                p.x += p.dx;
                p.y += p.dy;
            });

            ctx.lineCap = 'round';
            ctx.beginPath();
            ctx.moveTo(trail.current[0].x, trail.current[0].y);

            for (let i = 1; i < trail.current.length - 1; i++) {
                const xc = 0.5 * (trail.current[i].x + trail.current[i + 1].x);
                const yc = 0.5 * (trail.current[i].y + trail.current[i + 1].y);
                ctx.quadraticCurveTo(trail.current[i].x, trail.current[i].y, xc, yc);
                ctx.lineWidth = params.widthFactor * (params.pointsNumber - i);

                ctx.strokeStyle = `rgb(${Math.floor(rgbColor.current.r)}, ${Math.floor(rgbColor.current.g)}, ${Math.floor(rgbColor.current.b)})`;
                ctx.shadowBlur = params.glowIntensity;
                ctx.shadowColor = `rgb(${Math.floor(rgbColor.current.r)}, ${Math.floor(rgbColor.current.g)}, ${Math.floor(rgbColor.current.b)})`;

                ctx.stroke();
            }

            ctx.lineTo(trail.current[trail.current.length - 1].x, trail.current[trail.current.length - 1].y);
            ctx.stroke();

            window.requestAnimationFrame(update);
        };

        const handleMouseMove = (e: MouseEvent) => {
            updateMousePosition(e.pageX, e.pageY);
        };

        const handleTouchMove = (e: TouchEvent) => {
            updateMousePosition(e.touches[0].pageX, e.touches[0].pageY);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('resize', setupCanvas);

        setupCanvas();
        window.requestAnimationFrame(update);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('resize', setupCanvas);
        };
    }, []);

    return <canvas ref={canvasRef} style={{ width: '100vw', height: '100vh', background: 'transparent', position: 'fixed', zIndex: -1 }} />;
};

export default TrailEffect;

/* !!!! WEB GPUG */

// 'use client';

// import React, { useEffect, useRef } from 'react';

// interface Point {
//     x: number;
//     y: number;
//     dx: number;
//     dy: number;
// }

// interface RGBColor {
//     r: number;
//     g: number;
//     b: number;
// }

// const TrailEffect: React.FC = () => {
//     const canvasRef = useRef<HTMLCanvasElement | null>(null);
//     const pointer = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2 });

//     const params = {
//         pointsNumber: 20, // Увеличиваем количество точек для плавности
//         spring: 0.4,
//         friction: 0.5,
//         glowIntensity: 0.5, // Интенсивность свечения
//     };

//     const trail = useRef<Point[]>(
//         Array.from({ length: params.pointsNumber }, () => ({
//             x: window.innerWidth / 2,
//             y: window.innerHeight / 2,
//             dx: 0,
//             dy: 0,
//         }))
//     );

//     const colors: RGBColor[] = [
//         { r: 255, g: 0, b: 77 },
//         { r: 0, g: 255, b: 128 },
//         { r: 0, g: 128, b: 255 },
//         { r: 128, g: 0, b: 255 },
//         { r: 255, g: 255, b: 0 },
//     ];

//     const currentColorIndex = useRef(0);
//     const rgbColor = useRef<RGBColor>({ ...colors[0] });
//     const colorTransitionSpeed = 0.05;

//     const updateMousePosition = (x: number, y: number) => {
//         pointer.current.x = x;
//         pointer.current.y = y;
//     };

//     useEffect(() => {
//         const canvas = canvasRef.current;
//         if (!canvas) return;

//         // Установка размеров холста
//         canvas.width = window.innerWidth;
//         canvas.height = window.innerHeight;

//         // Обработчики событий
//         const handleMouseMove = (e: MouseEvent) => {
//             updateMousePosition(e.clientX, e.clientY);
//         };

//         const handleTouchMove = (e: TouchEvent) => {
//             updateMousePosition(e.touches[0].clientX, e.touches[0].clientY);
//         };

//         // Добавление обработчиков событий
//         window.addEventListener('mousemove', handleMouseMove);
//         window.addEventListener('touchmove', handleTouchMove);

//         let device: GPUDevice;
//         let context: GPUCanvasContext;

//         const setupWebGPU = async () => {
//             if (!('gpu' in navigator)) {
//                 console.error('WebGPU не поддерживается в этом браузере.');
//                 return;
//             }

//             const adapter = await navigator.gpu.requestAdapter();
//             if (!adapter) {
//                 console.error('Не удалось получить адаптер GPU.');
//                 return;
//             }

//             device = await adapter.requestDevice();
//             context = canvas.getContext('webgpu') as GPUCanvasContext;

//             const presentationFormat = navigator.gpu.getPreferredCanvasFormat();
//             context.configure({
//                 device,
//                 format: presentationFormat,
//                 alphaMode: 'premultiplied',
//             });

//             // Создание шейдеров
//             const vertexShaderCode = `
//                 struct VertexOutput {
//                     @builtin(position) position: vec4<f32>,
//                     @location(0) color: vec4<f32>,
//                 };

//                 @vertex
//                 fn main(@location(0) position: vec2<f32>, @location(1) color: vec4<f32>) -> VertexOutput {
//                     var output: VertexOutput;
//                     output.position = vec4<f32>(position, 0.0, 1.0);
//                     output.color = color;
//                     return output;
//                 }
//             `;

//             const fragmentShaderCode = `
//             @fragment
//             fn main(@location(0) color: vec4<f32>) -> @location(0) vec4<f32> {
//                 // Добавляем легкое размытие и эффект свечения
//                 let glowFactor: f32 = 2.7;  // Регулируем интенсивность glow
//                 let glowColor: vec4<f32> = vec4<f32>(color.rgb * glowFactor, color.a);
//                 return glowColor;

//                 }
//             `;

//             const shaderModule = {
//                 vertex: device.createShaderModule({ code: vertexShaderCode }),
//                 fragment: device.createShaderModule({ code: fragmentShaderCode }),
//             };

//             // Создание графического конвейера
//             const pipeline = device.createRenderPipeline({
//                 layout: 'auto',
//                 vertex: {
//                     module: shaderModule.vertex,
//                     entryPoint: 'main',
//                     buffers: [
//                         {
//                             arrayStride: 2 * 4, // позиция (x, y)
//                             attributes: [
//                                 {
//                                     shaderLocation: 0,
//                                     offset: 0,
//                                     format: 'float32x2',
//                                 },
//                             ],
//                         },
//                         {
//                             arrayStride: 4 * 4, // цвет (r, g, b, a)
//                             attributes: [
//                                 {
//                                     shaderLocation: 1,
//                                     offset: 0,
//                                     format: 'float32x4',
//                                 },
//                             ],
//                         },
//                     ],
//                 },
//                 fragment: {
//                     module: shaderModule.fragment,
//                     entryPoint: 'main',
//                     targets: [
//                         {
//                             format: presentationFormat,
//                             blend: {
//                                 color: {
//                                     srcFactor: 'src-alpha',
//                                     dstFactor: 'one-minus-src-alpha',
//                                     operation: 'add',
//                                 },
//                                 alpha: {
//                                     srcFactor: 'one',
//                                     dstFactor: 'zero',
//                                     operation: 'add',
//                                 },
//                             },
//                         },
//                     ],
//                 },
//                 primitive: {
//                     topology: 'line-strip',
//                 },
//             });

//             // Создание буферов
//             const vertexBufferSize = params.pointsNumber * 2 * 4;
//             const colorBufferSize = params.pointsNumber * 4 * 4;

//             const vertexBuffer = device.createBuffer({
//                 size: vertexBufferSize,
//                 usage: GPUBufferUsage.VERTEX | GPUBufferUsage.COPY_DST,
//             });

//             const colorBuffer = device.createBuffer({
//                 size: colorBufferSize,
//                 usage: GPUBufferUsage.VERTEX | GPUBufferUsage.COPY_DST,
//             });

//             // Функция рендеринга
//             const render = () => {
//                 // Обновление позиций следа
//                 trail.current.forEach((p, pIdx) => {
//                     const prev = pIdx === 0 ? pointer.current : trail.current[pIdx - 1];
//                     const spring = pIdx === 0 ? params.spring * 0.4 : params.spring;

//                     p.dx += (prev.x - p.x) * spring;
//                     p.dy += (prev.y - p.y) * spring;

//                     p.dx *= params.friction;
//                     p.dy *= params.friction;

//                     p.x += p.dx;
//                     p.y += p.dy;
//                 });

//                 // Обновление цвета
//                 const nextColorIndex = (currentColorIndex.current + 1) % colors.length;
//                 const nextColor = colors[nextColorIndex];

//                 rgbColor.current.r += (nextColor.r - rgbColor.current.r) * colorTransitionSpeed;
//                 rgbColor.current.g += (nextColor.g - rgbColor.current.g) * colorTransitionSpeed;
//                 rgbColor.current.b += (nextColor.b - rgbColor.current.b) * colorTransitionSpeed;

//                 if (
//                     Math.abs(rgbColor.current.r - nextColor.r) < 1 &&
//                     Math.abs(rgbColor.current.g - nextColor.g) < 1 &&
//                     Math.abs(rgbColor.current.b - nextColor.b) < 1
//                 ) {
//                     currentColorIndex.current = nextColorIndex;
//                 }

//                 // Преобразование позиций в NDC
//                 const ndcPositions = new Float32Array(params.pointsNumber * 2);
//                 for (let i = 0; i < params.pointsNumber; i++) {
//                     ndcPositions[2 * i] = (trail.current[i].x / canvas.width) * 2 - 1;
//                     ndcPositions[2 * i + 1] = -((trail.current[i].y / canvas.height) * 2 - 1);
//                 }

//                 // Обновление буфера вершин
//                 device.queue.writeBuffer(vertexBuffer, 0, ndcPositions);

//                 // Обновление буфера цветов
//                 const colorData = new Float32Array(params.pointsNumber * 4);
//                 const glowColor = [
//                     (rgbColor.current.r / 255) * params.glowIntensity,
//                     (rgbColor.current.g / 255) * params.glowIntensity,
//                     (rgbColor.current.b / 255) * params.glowIntensity,
//                     1.0,
//                 ];

//                 for (let i = 0; i < params.pointsNumber; i++) {
//                     colorData.set(glowColor, i * 4);
//                 }
//                 device.queue.writeBuffer(colorBuffer, 0, colorData);

//                 // Начало рендер-прохода
//                 const commandEncoder = device.createCommandEncoder();
//                 const textureView = context.getCurrentTexture().createView();
//                 const renderPassDescriptor: GPURenderPassDescriptor = {
//                     colorAttachments: [
//                         {
//                             view: textureView,
//                             loadOp: 'load', // Изменено с 'clear' на 'load'
//                             storeOp: 'store',
//                         },
//                     ],
//                 };

//                 const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor);
//                 passEncoder.setPipeline(pipeline);
//                 passEncoder.setVertexBuffer(0, vertexBuffer);
//                 passEncoder.setVertexBuffer(1, colorBuffer);
//                 passEncoder.draw(params.pointsNumber);
//                 passEncoder.end();

//                 device.queue.submit([commandEncoder.finish()]);

//                 requestAnimationFrame(render);
//             };

//             requestAnimationFrame(render);
//         };

//         setupWebGPU();

//         // Функция очистки
//         return () => {
//             window.removeEventListener('mousemove', handleMouseMove);
//             window.removeEventListener('touchmove', handleTouchMove);
//         };
//     }, []);

//     return <canvas ref={canvasRef} style={{ width: '100vw', height: '100vh', background: 'transparent', position: 'fixed', zIndex: -1 }} />;
// };

// export default TrailEffect;
