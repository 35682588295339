'use client';
// import { useEffect, useRef, useState } from 'react';
// import * as THREE from 'three';

// const ParticleWave = () => {
//     const containerRef = useRef<HTMLDivElement | null>(null);
//     const [mouseX, setMouseX] = useState(85);
//     const [mouseY, setMouseY] = useState(-342);
//     const [windowHalfX, setWindowHalfX] = useState(0);
//     const [windowHalfY, setWindowHalfY] = useState(0);

//     let particles: THREE.Sprite[] = [];
//     let camera: THREE.PerspectiveCamera;
//     let scene: THREE.Scene;
//     let renderer: THREE.WebGLRenderer | null = null;
//     let count = 0;

//     const SEPARATION = 150;
//     const AMOUNTX = 50;
//     const AMOUNTY = 35;

//     useEffect(() => {
//         setWindowHalfX(window.innerWidth / 2);
//         setWindowHalfY(window.innerHeight / 2);

//         init();
//         animate();

//         window.addEventListener('resize', onWindowResize);
//         return () => {
//             window.removeEventListener('resize', onWindowResize);
//         };
//     }, []);

//     const init = () => {
//         // Камера и сцена
//         camera = new THREE.PerspectiveCamera(120, window.innerWidth / window.innerHeight, 1, 10000);
//         camera.position.z = 1000;

//         scene = new THREE.Scene();

//         // Создание частиц
//         const material = new THREE.SpriteMaterial({
//             map: createParticleTexture(),
//             color: 0xe1e1e1,
//         });

//         let i = 0;

//         for (let ix = 0; ix < AMOUNTX; ix++) {
//             for (let iy = 0; iy < AMOUNTY; iy++) {
//                 const particle = (particles[i++] = new THREE.Sprite(material));
//                 particle.position.x = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
//                 particle.position.z = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;
//                 scene.add(particle);
//             }
//         }

//         // Рендерер WebGL
//         renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
//         renderer.setSize(containerRef.current?.clientWidth || window.innerWidth, containerRef.current?.clientHeight || window.innerHeight);
//         renderer.setPixelRatio(window.devicePixelRatio);
//         if (containerRef.current) {
//             containerRef.current.innerHTML = '';
//             containerRef.current.style.background = 'transparent';
//             containerRef.current.appendChild(renderer.domElement);
//         }
//     };

//     const createParticleTexture = () => {
//         const canvas = document.createElement('canvas');
//         canvas.width = 16;
//         canvas.height = 16;

//         const context = canvas.getContext('2d');
//         const gradient = context!.createRadialGradient(8, 8, 0, 8, 8, 8);
//         gradient.addColorStop(0, 'rgba(255,255,255,1)');
//         gradient.addColorStop(1, 'rgba(255,255,255,0)');

//         context!.fillStyle = gradient;
//         context!.fillRect(0, 0, 16, 16);

//         const texture = new THREE.Texture(canvas);
//         texture.needsUpdate = true;
//         return texture;
//     };

//     const onWindowResize = () => {
//         setWindowHalfX(window.innerWidth / 2);
//         setWindowHalfY(window.innerHeight / 2);

//         camera.aspect = window.innerWidth / window.innerHeight;
//         camera.updateProjectionMatrix();

//         renderer?.setSize(window.innerWidth, window.innerHeight);
//     };

//     const animate = () => {
//         requestAnimationFrame(animate);
//         renderScene();
//     };

//     let needsRender = true;

//     const renderScene = () => {
//         camera.position.x += (mouseX - camera.position.x) * 0.05;
//         camera.position.y += (-mouseY - camera.position.y) * 0.05;
//         camera.lookAt(scene.position);

//         let i = 0;

//         for (let ix = 0; ix < AMOUNTX; ix++) {
//             for (let iy = 0; iy < AMOUNTY; iy++) {
//                 const particle = particles[i++];
//                 particle.position.y = Math.sin((ix + count) * 0.1) * 50 + Math.sin((iy + count) * 0.5) * 50;
//                 particle.scale.x = particle.scale.y = (Math.sin((ix + count) * 0.3) + 1) * 2 + (Math.sin((iy + count) * 0.5) + 1) * 2;
//             }
//         }

//         renderer?.render(scene, camera);
//         count += 0.03;
//     };

//     return <div ref={containerRef} className=' z-[-1] fixed top-[120px]' />;
// };

// export default ParticleWave;

/* WEB GL */
'use client';
import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

const ParticleWave = () => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    // Добавляем состояния для позиции мыши
    const [mouseX, setMouseX] = useState(85);
    const [mouseY, setMouseY] = useState(-342);
    const [windowHalfX, setWindowHalfX] = useState(window.innerWidth / 2);
    const [windowHalfY, setWindowHalfY] = useState(window.innerHeight / 2);

    useEffect(() => {
        let camera: THREE.PerspectiveCamera;
        let scene: THREE.Scene;
        let renderer: THREE.WebGLRenderer;
        let animationId: number;

        const SEPARATION = 100;
        const AMOUNTX = 100;
        const AMOUNTY = 70;
        let count = 0;

        const init = () => {
            camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 5000);
            camera.position.z = 1000;

            scene = new THREE.Scene();

            // Создаем геометрию для точек
            const numParticles = AMOUNTX * AMOUNTY;
            const positions = new Float32Array(numParticles * 3);
            const colors = new Float32Array(numParticles * 3); // Добавляем массив цветов

            let index = 0;

            for (let ix = 0; ix < AMOUNTX; ix++) {
                for (let iy = 0; iy < AMOUNTY; iy++) {
                    positions[index] = ix * SEPARATION - (AMOUNTX * SEPARATION) / 2; // x
                    positions[index + 1] = 0; // y (мы будем обновлять эту координату)
                    positions[index + 2] = iy * SEPARATION - (AMOUNTY * SEPARATION) / 2; // z

                    // Инициализируем цвета белым цветом
                    colors[index] = 1.0; // r
                    colors[index + 1] = 1.0; // g
                    colors[index + 2] = 1.0; // b

                    index += 3;
                }
            }

            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
            geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3)); // Добавляем атрибут цвета

            // Загрузка текстуры для частиц (необязательно)
            const textureLoader = new THREE.TextureLoader();
            const sprite = textureLoader.load('/assets/images/sprite.png'); // Замените на путь к вашей текстуре

            // Создаем материал для точек
            const material = new THREE.PointsMaterial({
                size: 20,
                sizeAttenuation: true,
                transparent: true,
                opacity: 1,
                map: sprite, // Добавляем текстуру
                vertexColors: true, // Используем цвета вершин
                blending: THREE.AdditiveBlending,
                depthTest: false,
            });

            const particles = new THREE.Points(geometry, material);
            scene.add(particles);

            renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.setPixelRatio(window.devicePixelRatio);

            if (containerRef.current) {
                containerRef.current.innerHTML = '';
                containerRef.current.appendChild(renderer.domElement);
            }

            window.addEventListener('resize', onWindowResize, false);

            animate();
        };

        const onWindowResize = () => {
            setWindowHalfX(window.innerWidth / 2);
            setWindowHalfY(window.innerHeight / 2);

            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize(window.innerWidth, window.innerHeight);
        };

        const animate = () => {
            animationId = requestAnimationFrame(animate);

            render();
        };

        const render = () => {
            // Обновляем позицию камеры на основе позиции мыши
            camera.position.x += (mouseX - camera.position.x) * 0.05;
            camera.position.y += (-mouseY - camera.position.y) * 0.05;
            camera.lookAt(scene.position);

            const particles = scene.children[0] as THREE.Points;
            const positions = particles.geometry.attributes.position.array as Float32Array;
            const colors = particles.geometry.attributes.color.array as Float32Array;

            let index = 0;

            for (let ix = 0; ix < AMOUNTX; ix++) {
                for (let iy = 0; iy < AMOUNTY; iy++) {
                    // Обновляем позицию по Y для эффекта волны
                    positions[index + 1] = (Math.sin((ix + count) * 0.3) + Math.sin((iy + count) * 0.5)) * 50;

                    // Обновляем цвета для создания переливающегося эффекта
                    const color = new THREE.Color();
                    color.setHSL((ix + count) / AMOUNTX, 1.0, 0.5);

                    colors[index] = color.r;
                    colors[index + 1] = color.g;
                    colors[index + 2] = color.b;

                    index += 3;
                }
            }

            particles.geometry.attributes.position.needsUpdate = true;
            particles.geometry.attributes.color.needsUpdate = true;

            renderer.render(scene, camera);

            count += 0.03;
        };

        init();

        return () => {
            cancelAnimationFrame(animationId);
            renderer.dispose();
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    return <div ref={containerRef} className='z-[-1] fixed top-[120px]' />;
};

export default ParticleWave;
